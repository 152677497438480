import React from 'react'
import { graphql, Link } from 'gatsby'
import { Container, Row, Col, CardDeck } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Breadcrumbs from '../components/breadcrumbs'
import Card from '../components/card'

const PortfolioListTemplate = ({ data }) => {
  return (
    <Layout currentSection="portfolio">
      <SEO
        title="My Work | Digital Marketing Case Studies"
        description=""
        canonicalUrl=""
        meta={[
          {
            name: 'twitter:creator',
            content: '@chrisdpeters',
          },
        ]}
      />

      <Section>
        <Container>
          <Row>
            <Col>
              <Breadcrumbs />

              <h1 className="mb-5">
                My Work
              </h1>

              <CardDeck>
                {data.portfolio.nodes.map((portfolio, i) => {
                  const url = '/portfolio' + portfolio.fields.slug

                  return (
                    <Card key={i}>
                      <Card.Img
                        variant="top"
                        src={portfolio.frontmatter.cardImage.childImageSharp.resize.src}
                        alt=""
                      />

                      <Card.Body>
                        <Card.Title>
                          <Link to={url} className="link-heading heading-dec heading-dec-light">
                            {portfolio.frontmatter.title}
                          </Link>
                        </Card.Title>
                      </Card.Body>

                      <Card.LinkOverlay to={url}>
                        {portfolio.frontmatter.title}
                      </Card.LinkOverlay>
                    </Card>
                  )
                })}
              </CardDeck>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioList {
    portfolio: allMarkdownRemark(
      filter: { fields: { content_type: { eq: "portfolio" } } }
      sort: { order: DESC, fields: frontmatter___year }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          cardImage: card_image {
            childImageSharp {
              resize(width: 1200, height: 750) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default PortfolioListTemplate
