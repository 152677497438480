import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import { Link } from 'gatsby'

Card.LinkOverlay = ({ to, children }) => {
  return (
    <Link to={to} className="card-link-overlay">
      <span className="sr-only">
        {children}
      </span>
    </Link>
  )
}

Card.LinkOverlay.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Card
